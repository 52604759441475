export const configVO = () => {
  return {
    id: '',
    type: {
      id: ''
    },
    cnName: '',
    code: '',
    core: '',
    enName: '',
    distinguishedName: '',
    escapeContent: '',
    maxValue: '',
    minValue: '',
    metricsType: '',
    unit: '',
    sorter: '',
    new: true,
    orgId: 0
  }
}
export const typeFormVO = () => {
  return {
    id: '',
    name: '',
    parentId: null,
    sorter: ''
  }
}
