<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="指标类型" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-tree-select
          v-decorator="['type.id', {initialValue:defaultType, rules: [{ required: true, message: '请选择类型' }] }]"
          allowClear
          :treeData="treeData"
          placeholder="请选择指标类型"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="指标编码" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['code', { rules: [{ required: true, message: '请输入指标编码' }] }]"
          placeholder="请输入指标编码"
        ></a-input>
      </a-form-item>
      <a-form-item label="指标类型" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-select
          placeholder="请选择指标类型"
          v-decorator="['metricsType', { initialValue: 'yc', rules: [{ required: true, message: '请选择指标类型' }] }]"
          :allowClear="true"
          @select="selectMetricsType"
        >
          <a-select-option v-for="item in metricsType" :key="item.value" :value="item.value">{{
            item.name
            }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="中文名称" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['cnName', { rules: [{ required: true, message: '请输入指标中文名称' }] }]"
          placeholder="请输入指标中文名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="英文名称" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['enName', { rules: [{ required: true, message: '请输入指标英文名称' }] }]"
          placeholder="请输入指标英文名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="标识名" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['distinguishedName', { rules: [{ required: true, message: '请输入指标标识名' }] }]"
          placeholder="请输入指标标识名"
        ></a-input>
      </a-form-item>
      <template v-if="selectMetricsTypeValue === 'yc'">
        <a-form-item label="指标单位" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input v-decorator="['unit']" placeholder="请输入"></a-input>
        </a-form-item>
        <a-form-item label="指标区间" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input style="width: 155px;" v-decorator="['minValue']" placeholder="请输入最小值"></a-input>
          ~
          <a-input style="width: 160px;" v-decorator="['maxValue']" placeholder="请输入最大值"></a-input>
        </a-form-item>
      </template>
      <a-form-item
        label="转义"
        :label-col="questionLabelCol"
        :wrapper-col="questionWrapperCol"
        v-if="selectMetricsTypeValue === 'yx'"
      >
        0:&nbsp;&nbsp;<a-input style="width: 135px;" v-decorator="['zero']" placeholder="请输入"></a-input>
        &nbsp;&nbsp;&nbsp; 1:&nbsp;&nbsp;<a-input
        style="width: 135px;"
        v-decorator="['one']"
        placeholder="请输入"
      ></a-input>
      </a-form-item>
      <a-form-item label="是否是核心" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-radio-group :options="coreList" v-model="value"/>
      </a-form-item>
      <a-form-item label="排序" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input-number :min="1" v-decorator="['sorter']"/>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { configVO } from './common/configVO'
  import { metricsType } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'

  export default {
    name: 'Form',
    components: {},
    mixins: [entityCURDCallback],
    props: {
      typeTree: {
        type: Array
      },
      categoryList: {
        type: Array
      },
      defaultType: {
        type: [String, Number]
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentConfigApi,
        initFormItem: configVO(),
        showFormItem: false,
        metricsType: metricsType(),
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        coreList: [
          { value: 'true', label: '是' },
          { value: 'false', label: '否' }
        ],
        value: 'true',
        yxList: [
          { value: '0', label: '开' },
          { value: '1', label: '关' }
        ],
        ycList: [
          { value: '0', label: '正常' },
          { value: '1', label: '异常' }
        ],
        escape: null,
        selectMetricsTypeValue: 'yc',
        treeData: [],
      }
    },
    created () {
      this.getTypeTree()
    },
    methods: {
      getTypeTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.metricsType.getTree,
          noTips: true,
          success: (data) => {
            this.treeData = []
            this.treeData.push(data.body)
          }
        })
      },
      // 把form取到的值 赋给创建的formItem
      setVOFields (values) {
        if (values.metricsType === 'yx') {
          this.escape = { zero: values.zero, one: values.one }
        }
        this.formItem = {
          type: {
            id: values.type.id
          },
          cnName: values.cnName,
          enName: values.enName,
          distinguishedName: values.distinguishedName,
          code: values.code,
          maxValue: values.maxValue,
          minValue: values.minValue,
          unit: values.unit,
          metricsType: values.metricsType,
          core: this.value,
          sorter: values.sorter,
          new: true,
          orgId: 0,
          escapeContent: this.escape
        }
      },
      // 给form赋值
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          cnName: values.cnName,
          enName: values.enName,
          distinguishedName: values.distinguishedName,
          code: values.code,
          metricsType: values.metricsType,
          sorter: values.sorter,
          'type.id': values.type.id
        })
        this.value = values.core.toString()
        this.selectMetricsTypeValue = values.metricsType
        if (this.selectMetricsTypeValue === 'yc') {
          this.form.setFieldsValue({
            maxValue: values.maxValue,
            minValue: values.minValue,
            unit: values.unit
          })
        }
        if (this.selectMetricsTypeValue === 'yx') {
          this.$nextTick(() => {
            this.form.setFieldsValue({
              zero: JSON.parse(values.escapeContent).zero,
              one: JSON.parse(values.escapeContent).one
            })
          })
        }
      },
      selectMetricsType (value) {
        this.selectMetricsTypeValue = value
        if (value === 'yc') {
          this.form.resetFields({ zero: '', one: '' })
        } else if (value === 'yx') {
          this.form.resetFields({
            maxValue: '', minValue: '', unit: ''
          })
        } else {
          this.form.resetFields({ maxValue: '', minValue: '', unit: '', zero: '', one: '' })
        }
      },
      addSuccessCustomCallback () {
        this.escape = null
        this.selectMetricsTypeValue = 'yc'
      }
    }
  }
</script>

<style scoped></style>
